import React, { useEffect, useState, useContext } from "react";
import {
  Animate,
  Button,
  Content,
  useAPI,
  useLocation,
  useNavigate,
  ViewContext,
} from "components/lib";
import { useForm, Controller } from "react-hook-form";
import {
  getBanks,
  getBranchXProduct,
  getCard,
  getWayPaid,
  postFormSave,
  Submit,
} from "components/bridges/BridgeServices";
import Style from "../../agriculture/agricultureSeller.module.scss";
import GlobalStyle from "../../../../css/globalStyle.module.scss";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { Title } from "./components/title";
import { BackButton } from "components/backButton/button";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../css/reactCalendar.css";
import es from "date-fns/locale/es";
import { SetViewCandidate } from "utils/getPermissions";
import { useDataContext } from "../../../../context/DataContext";

export function FormDebit(props) {
  const rawUser = JSON.parse(localStorage.getItem('profile'))
  const userPermissions = JSON.parse(localStorage.getItem("user_permissions"));
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const viewContext = useContext(ViewContext);
  const {
    watch,
    register,
    control,
    handleSubmit,
    formState: { errors },
    formState,
    setValue,
  } = useForm();
  registerLocale("es", es);
  const { isSubmitting } = formState;
  const [options, setOptions] = useState({
    personeria: [],
    tipoId: [],
    genero: [],
    mediopago: [],
    tipoPlan: [],
    planOptions: [],
    branchProduct: {},
    nacionalidades: [],
    provincias: [],
    estadoCivil: [],
    pais: [],
    actividadEconomica: [],
    tarjetas: [],
    formaPago: [],
    bancos: [],
  });
  let url = useLocation();
  let dataInfo = url.state.plan[1];
  const [setFechaVencimientoTarjeta] = useState(new Date());
  let productSelected = localStorage.getItem("productSelected");
  const [isLoadin, setIsLoading] = useState(false);
  const [numberCard, setNumberCard] = useState("");
  const [numberAccount, setNumbeAccount] = useState("");
  const [numberCardReal, setNumberCardReal] = useState("");
  const [numberAccountReal, setNumbeAccountReal] = useState("");
  /////////////////////////DataContext implementation/////////////////////////
  const {
    state
  } = useDataContext();
  ////////////////////////////////////////////////////////////////////////////
  const mediopago = watch("mediopago");
  let vecUrl = url.pathname.split("/");
  let candidateId = vecUrl[vecUrl.length - 1];
  const formsFilled = useAPI(
    `/api/formByFilter?candidate_id=${candidateId}&product_name=product${(
      parseInt(productSelected) + 1
    ).toString()}`
  );

  const onChangeValue = async (value) => { };

  const getOptions = async () => {
    try {
      setIsLoading(true);
      let tempOptions = {
        ...options,
      };
      const respCardsV2 = localStorage.getItem('cards')
      let respCardsJson;
      if (respCardsV2) {
        respCardsJson = JSON.parse(respCardsV2)?.data
      }
      const respCards = state?.cards?.data ? state.cards.data : respCardsJson ? respCardsJson : await getCard();
      //
      const respWayPaidV2 = localStorage.getItem('wayPaid')
      let respWayPaidJson;
      if (respWayPaidV2) {
        respWayPaidJson = JSON.parse(respWayPaidV2)?.data
      }
      const respWayPaid = state?.wayPaid?.data ? state.wayPaid.data : respWayPaidJson ? respWayPaidJson : await getWayPaid();
      //
      const respBanksV2 = localStorage.getItem('banks')
      let respBanksJson;
      if (respBanksV2) {
        respBanksJson = JSON.parse(respBanksV2)?.data
      }
      const respBanks = state?.banks?.data ? state.banks.data : respBanksJson ? respBanksJson : await getBanks();

      respBanks.map((item) => {
        tempOptions.bancos.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "formaPago",
        });
      });

      respWayPaid.map((item) => {
        tempOptions.formaPago.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "formaPago",
        });
      });

      respCards.map((item) => {
        tempOptions.tarjetas.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "tarjetas",
        });
      });

      let obj = {
        ramo: "20",
        ahorro: "S",
      };
      const respBranchXProductV2 = localStorage.getItem('branchXProducts')
      let respBranchXProductJson;
      if (respBranchXProductV2) {
        respBranchXProductJson = JSON.parse(respBranchXProductV2)?.data
      }
      const respBranchXProduct = state?.branchXProducts?.data ? state.branchXProducts.data :
        respBranchXProductJson ? respBranchXProductJson : await getBranchXProduct(obj);
      tempOptions.branchProduct = respBranchXProduct;
      setOptions(tempOptions);
      setIsLoading(false);
    } catch (error) {
      viewContext.handleError(error);
    }
  };

  const hideNumber = (number) => {
    const words = number.split("");
    let tempString = "";

    for (let i = 0; i <= words.length; i++) {
      if (i <= words.length - 4) {
        tempString += "*";
      } else {
        tempString += words[i - 1];
      }
    }
    return tempString;
  };

  const hideMail = (mail) => {
    const words1 = mail.split("@");
    const words = words1[0].split("");
    let tempString = "";

    for (let i = 0; i <= words.length; i++) {
      if (i <= words.length - 4) {
        tempString += "*";
      } else {
        tempString += words[i - 1];
      }
    }
    tempString += "@" + words1[1];
    return tempString;
  };

  const getBankName = (code) => {
    let name = "";
    options.bancos.map((item) => {
      if (code === item.value) {
        name = item.label;
      }
    });
    return name;
  };

  const onSubmit = async (data) => {
    let productSelected = localStorage.getItem("productSelected");
    let idForm = "";
    let existForm = false;

    formsFilled.data.map((item) => {
      if (item.type === "autorizacion_debito") {
        existForm = true;
        idForm = item.id;
      }
    });
    let context =
      mediopago === "21"
        ? {
          primerApellido: dataInfo.client.apellidoPaterno,
          segundoApellido: dataInfo.client.apellidoMaterno,
          primerNombre: dataInfo.client.primerNombre,
          segundoNombre: dataInfo.client.segundoNombre,
          ced: dataInfo.client.tipoId === "00" ? "X" : "",
          pas: dataInfo.client.tipoId === "01" ? "X" : "",
          nroidentificacion: dataInfo.client.numeroDocumento,
          autorizo: "Si",
          mediopago: data.mediopago,
          medio: "tarjeta",
          banco: data.bancotarjeta,
          marcatarjeta: data.marcatarjeta,
          bancotarjeta: await getBankName(data.bancotarjeta),
          numerotarjeta: numberCardReal,
          fechavencimiento: data.fechavencimiento,
          numerotarjetanovisible: data.numerotarjetanovisible,
          cred: "X",
          aho: "",
          cor: "",
          vis:
            (numberCardReal.slice(0, 1) === "4" &&
              data.marcatarjeta === "00018") ||
              data.marcatarjeta === "00031" ||
              data.marcatarjeta === "00037" ||
              data.marcatarjeta === "00039" ||
              data.marcatarjeta === "00041" ||
              data.marcatarjeta === "05"
              ? "X"
              : "",
          din:
            (numberCardReal.slice(0, 2) === "36" &&
              data.marcatarjeta === "00028") ||
              data.marcatarjeta === "04"
              ? "X"
              : "",
          ame:
            numberCardReal.slice(0, 2) === "37" && data.marcatarjeta === "01"
              ? "X"
              : "",
          mas:
            (numberCardReal.slice(0, 1) === "5" &&
              data.marcatarjeta === "00018") ||
              data.marcatarjeta === "00036" ||
              data.marcatarjeta === "00038" ||
              data.marcatarjeta === "00040" ||
              data.marcatarjeta === "03"
              ? "X"
              : "",
          anio: new Date(data.fechavencimiento).getFullYear().toString(),
          mes: (
            new Date(data.fechavencimiento).getMonth().valueOf() + 1
          ).toString(),
          correo: hideMail(dataInfo.client.correo),
          telefono: hideNumber(dataInfo.client.telefono),
          celular: hideNumber(dataInfo.client.telefono),
          correoVisible: dataInfo.client.correo,
          telefonoVisible: dataInfo.client.telefono,
          celularVisible: dataInfo.client.telefono,
          men: dataInfo.client.typePaid === "M" ? "X" : "",
          sem: dataInfo.client.typePaid === "S" ? "X" : "",
          anu: dataInfo.client.typePaid === "A" ? "X" : "",
          tri: dataInfo.client.typePaid === "T" ? "X" : "",
          lug_fecha:
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth().valueOf() + 1) +
            "-" +
            new Date().getDate(),
          valor_mensual:
            dataInfo.dataProjection.data.pagos[0].adec_pag_valor_total.toString(),
        }
        : {
          primerApellido: dataInfo.client.apellidoPaterno,
          segundoApellido: dataInfo.client.apellidoMaterno,
          primerNombre: dataInfo.client.primerNombre,
          segundoNombre: dataInfo.client.segundoNombre,
          ced: dataInfo.client.tipoId === "00" ? "X" : "",
          pas: dataInfo.client.tipoId === "01" ? "X" : "",
          tipoidentificacion: dataInfo.client.tipoDocumento,
          nroidentificacion: dataInfo.client.numeroDocumento,
          autorizo: "Si",
          mediopago: data.mediopago,
          medio: "cuenta",
          banco: data.banco,
          bancotarjeta: getBankName(data.banco),
          tipocuenta:
            data.mediopago === "DEB. AUT. CORRIENTE"
              ? "Cuenta Corriente"
              : "Cuenta Ahorro",
          numerocuenta: numberAccountReal,
          numerocuentanovisible: data.numerocuentanovisible,
          vis:
            (numberCardReal.slice(0, 1) === "4" &&
              data.marcatarjeta === "00018") ||
              data.marcatarjeta === "00031" ||
              data.marcatarjeta === "00037" ||
              data.marcatarjeta === "00039" ||
              data.marcatarjeta === "00041" ||
              data.marcatarjeta === "05"
              ? "X"
              : "",
          din:
            (numberCardReal.slice(0, 2) === "36" &&
              data.marcatarjeta === "00028") ||
              data.marcatarjeta === "04"
              ? "X"
              : "",
          ame:
            numberCardReal.slice(0, 2) === "37" && data.marcatarjeta === "01"
              ? "X"
              : "",
          mas:
            (numberCardReal.slice(0, 1) === "5" &&
              data.marcatarjeta === "00018") ||
              data.marcatarjeta === "00036" ||
              data.marcatarjeta === "00038" ||
              data.marcatarjeta === "00040" ||
              data.marcatarjeta === "03"
              ? "X"
              : "",
          anio: "",
          mes: "",
          correo: hideMail(dataInfo.client.correo),
          telefono: hideNumber(dataInfo.client.telefono),
          celular: hideNumber(dataInfo.client.telefono),
          correoVisible: dataInfo.client.correo,
          telefonoVisible: dataInfo.client.telefono,
          celularVisible: dataInfo.client.telefono,
          men: dataInfo.client.typePaid === "M" ? "X" : "",
          sem: dataInfo.client.typePaid === "S" ? "X" : "",
          anu: dataInfo.client.typePaid === "A" ? "X" : "",
          tri: dataInfo.client.typePaid === "T" ? "X" : "",
          cred: "",
          aho: data.mediopago === "13" ? "X" : "",
          cor: data.mediopago === "05" ? "X" : "",
          lug_fecha:
            new Date().getFullYear() +
            "-" +
            (new Date().getMonth().valueOf() + 1) +
            "-" +
            new Date().getDate(),
          valor_mensual:
            dataInfo.dataProjection.data.pagos[0].adec_pag_valor_total.toString(),
        };
    context["estado"] = "Completo";
    try {
      let req = {
        type: "autorizacion_debito",
        detail: context,
        product_name: `product${(parseInt(productSelected) + 1).toString()}`,
      };
      if (existForm) {
        req["id"] = idForm;
        await Submit("form", req, "patch").then((resp) => {
          viewContext.notification.show("Formulario guardado", "success", true);
        });
      } else {
        req["candidate_id"] = candidateId;
        await postFormSave(req).then((resp) => {
          viewContext.notification.show("Formulario guardado", "success", true);
        });
      }

    } catch (error) {
      viewContext.notification.show(error, "error", true);
      console.error(error);
    }

    try {
      let reqUpdatePrice = {
        id: candidateId,
        estado: "Pendiente de documentos",
      };
      await Submit("candidate/updateStep", reqUpdatePrice, "patch").then(
        (resp) => {
          navigate(-1);
        }
      );
    } catch (error) {
      viewContext.notification.show(error, "error", true);
      console.error(error);
    }
  };

  const getValuesForm = () => {
    try {
      if (!formsFilled.loading && formsFilled.data && options) {
        formsFilled.data.map((item) => {
          if (item.type === "autorizacion_debito") {
            Object.keys(item.detail).map((question) => {
              if (question === "fechavencimiento") {
                setValue(question, new Date(item.detail[question]));
              } else {
                setValue(question, item.detail[question]);
              }
            });
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNumberCard = (number) => {
    if (number.target.value !== "") {
      let temp = "";
      let tempNumberReal = numberCardReal;

      [...number.target.value].map((item, index) => {
        if (index > number.target.value.length - 5) {
          temp += item;
        } else {
          temp += "*";
        }
      });

      if (number.nativeEvent.data !== null) {
        tempNumberReal += number.nativeEvent.data;
      } else {
        tempNumberReal = tempNumberReal.substring(0, tempNumberReal.length - 1);
      }

      setNumberCard(temp);
      setNumberCardReal(tempNumberReal);
    } else {
      setNumberCard("");
      setNumberCardReal("");
    }
  };

  const handleNumberAccount = (number) => {
    if (number.target.value !== "") {
      let temp = "";
      let tempAccountReal = numberAccountReal;
      [...number.target.value].map((item, index) => {
        if (index > number.target.value.length - 5) {
          temp += item;
        } else {
          temp += "*";
        }
      });

      if (number.nativeEvent.data !== null) {
        tempAccountReal += number.nativeEvent.data;
      } else {
        tempAccountReal = tempAccountReal.substring(
          0,
          tempAccountReal.length - 1
        );
      }

      setNumbeAccount(temp);
      setNumbeAccountReal(tempAccountReal);
    } else {
      setNumbeAccount("");
      setNumbeAccountReal("");
    }
  };

  useEffect(() => {
    getValuesForm();
  }, [formsFilled, options]);

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <Animate type='pop'>
      <div className={GlobalStyle.buttonPosition}>
        <BackButton go={-1} />
      </div>
      <section className={Style.paddingLeft}>
        <Content>
          <div style={{ padding: "15px 0px" }}>
            <p className={GlobalStyle.textTitle}>
              Formulario autorización de débito
            </p>
          </div>
          {!isLoadin ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Title whiteTitle='Formulario autorización de débito' />
              {step === 0 && (
                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px 15px",
                    }}
                  >
                    <label>
                      Seleccione el medio de cobro, por el cual se debitará el
                      seguro del cliente:
                    </label>
                    <br />
                    <div onChange={onChangeValue}>
                      {options.formaPago.map((opcion, index) => {
                        return (
                          <div
                            key={`mediopago${index}${opcion.value}`}
                            style={{ display: "flex" }}
                          >
                            <div style={{ width: "5%" }}>
                              <input
                                {...register("mediopago", {
                                  required: true,
                                })}
                                type='radio'
                                value={opcion.value}
                              />
                              {errors.mediopago?.type === "required" && (
                                <p style={{ color: "red" }}>
                                  Este campo es requerido
                                </p>
                              )}
                            </div>
                            <div>
                              <label style={{ padding: "0px 10px" }}>
                                {opcion.label}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      padding: "15px 0px",
                      flexDirection: "row-reverse",
                      display: "flex",
                    }}
                  >
                    <Button
                      applyColor='#0f265c'
                      disabled={
                        mediopago === null || mediopago === undefined
                          ? true
                          : false
                      }
                      text='Guardar'
                      action={() => setStep(1)}
                    />
                  </div>
                </div>
              )}
              {step === 1 && (
                <div>
                  <label>
                    Seleccione el medio de cobro, por el cual se debitará el
                    seguro del cliente:
                  </label>
                  <br />
                  {mediopago === "21" ? (
                    <>
                      {" "}
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className={GlobalStyle.containerTarjeta}>
                          <div
                            style={{ width: "100%", backgroundColor: "white" }}
                          >
                            <label>Tarjeta de crédito</label>
                            <div>
                              <select
                                {...register(`marcatarjeta`, {
                                  required: true,
                                })}
                                style={{
                                  width: "100%",
                                }}
                                className={GlobalStyle["select"]}
                              >
                                {options &&
                                  options.tarjetas &&
                                  options.tarjetas.map((item) => {
                                    return (
                                      <option
                                        key={`tarjetas${item.value}`}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    );
                                  })}
                              </select>
                              {errors.marcatarjeta?.type === "required" && (
                                <p style={{ color: "red" }}>
                                  Este campo es requerido
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={GlobalStyle.containerTarjeta}>
                          <div
                            style={{ width: "100%", backgroundColor: "white" }}
                          >
                            <label>Número</label>
                            <div>
                              <input
                                className={GlobalStyle["input_number"]}
                                {...register("numerotarjetanovisible", {
                                  required: true,
                                  value: numberCard,
                                })}
                                name='numerotarjetanovisible'
                                type='text'
                                value={`${numberCard}`}
                                onChange={(e) => {
                                  handleNumberCard(e);
                                }}
                                maxLength={"16"}
                                style={{
                                  padding: "16px 8px",
                                  backgroundColor: "hsl(0, 0%, 100%)",
                                  borderColor: "hsl(0, 0%, 80%)",
                                  borderRadius: "4px",
                                  borderStyle: "solid",
                                  borderWidth: "1px",
                                  width: "100%",
                                  display: "flex",
                                  minHeight: "38px",
                                  outline: "0!important",
                                }}
                              />
                              {errors.numerotarjetanovisible?.type ===
                                "required" && (
                                  <p style={{ color: "red" }}>
                                    Este campo es requerido
                                  </p>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className={GlobalStyle.containerTarjeta}>
                          <div
                            style={{ width: "100%", backgroundColor: "white" }}
                          >
                            <label>Banco</label>
                            <div>
                              <select
                                {...register(`bancotarjeta`, {
                                  required: true,
                                })}
                                style={{
                                  width: "100%",
                                }}
                                className={GlobalStyle["select"]}
                              >
                                {options &&
                                  options.bancos &&
                                  options.bancos.map((item) => {
                                    return (
                                      <option
                                        key={`bancos${item.value}`}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    );
                                  })}
                              </select>
                              {errors.bancotarjeta?.type === "required" && (
                                <p style={{ color: "red" }}>
                                  Este campo es requerido
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={GlobalStyle.containerTarjeta}>
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "white",
                            }}
                          >
                            <label style={{ paddingRight: 15 }}>
                              Fecha vencimiento tarjeta
                            </label>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                padding: "10px 15px",
                                alignSelf: "center",
                                backgroundColor: "#F7F7F8",
                                borderRadius: "5px",
                              }}
                            >
                              <Controller
                                name='fechavencimiento'
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <DatePicker
                                    placeholderText='Selecciona una fecha'
                                    onChange={(e) => {
                                      field.onChange(e);
                                      setFechaVencimientoTarjeta(e);
                                    }}
                                    selected={field.value}
                                    dateFormat='MM/yyyy'
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    locale='es'
                                  />
                                )}
                              />
                            </div>
                            {errors.fechavencimiento?.type === "required" && (
                              <p style={{ color: "red" }}>
                                Este campo es requerido
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          padding: "10px 15px",
                        }}
                      >
                        <div className={GlobalStyle.containerTarjeta}>
                          <div
                            style={{ width: "100%", backgroundColor: "white" }}
                          >
                            <label>Banco</label>
                            <select
                              {...register(`banco`, { required: true })}
                              style={{
                                width: "100%",
                              }}
                              className={GlobalStyle["select"]}
                            >
                              {options &&
                                options.bancos &&
                                options.bancos.map((item) => {
                                  return (
                                    <option
                                      key={`bancos${item.value}`}
                                      value={item.value}
                                    >
                                      {item.label}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.banco?.type === "required" && (
                              <p style={{ color: "red" }}>
                                Este campo es requerido
                              </p>
                            )}
                          </div>
                        </div>
                        <div className={GlobalStyle.containerTarjeta}>
                          <div
                            style={{ width: "100%", backgroundColor: "white" }}
                          >
                            <label>Número de cuenta</label>
                            <input
                              className={GlobalStyle["input_number"]}
                              {...register("numerocuentanovisible", {
                                required: true,
                                value: numberAccount,
                              })}
                              name='numerocuentanovisible'
                              type='text'
                              value={`${numberAccount}`}
                              onChange={(e) => {
                                handleNumberAccount(e);
                              }}
                              onKeyDown={(e) => null}
                              maxLength={"15"}
                              style={{
                                padding: "16px 8px",
                                backgroundColor: "hsl(0, 0%, 100%)",
                                borderColor: "hsl(0, 0%, 80%)",
                                borderRadius: "4px",
                                borderStyle: "solid",
                                borderWidth: "1px",
                                width: "100%",
                                display: "flex",
                                minHeight: "38px",
                                outline: "0!important",
                              }}
                            />
                            {errors.numerocuentanovisible?.type ===
                              "required" && (
                                <p style={{ color: "red" }}>
                                  Este campo es requerido
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div>
                    {!isSubmitting ? (
                      <div className={GlobalStyle.containerButtonesDebito}>
                        <div className={GlobalStyle.containerRegresar}>
                          <Button
                            applyColor='#0f265c'
                            text='Regresar'
                            action={() => setStep(0)}
                          />
                        </div>
                        {SetViewCandidate(
                          rawUser,
                          userPermissions,
                          ["u"],
                          "cotizaciones"
                        ) && (
                            <div className={GlobalStyle.containerGuardar}>
                              <input
                                type='submit'
                                value='Guardar'
                                style={{
                                  cursor: "pointer",
                                  padding: "1em 3em",
                                  backgroundColor: "#0f265c",
                                  color: "white",
                                  borderRadius: "0.25em",
                                  fontSize: "1em",
                                  fontWeight: 600,
                                  textAlign: "center",
                                  textDecoration: "none",
                                  width: "186px",
                                }}
                              />
                            </div>
                          )}
                      </div>
                    ) : (
                      <div className={Style.ldsDualRing}></div>
                    )}
                  </div>
                </div>
              )}
            </form>
          ) : (
            <div className={Style.ldsDualRing}></div>
          )}
        </Content>
      </section>
    </Animate>
  );

}
